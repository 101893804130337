import "./bootstrap";
import "../css/app.css";

import { persistor, store } from "@/redux/store";
import { createInertiaApp } from "@inertiajs/inertia-react";
import { InertiaProgress } from "@inertiajs/progress";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const appName =
	window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

void createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) =>
		resolvePageComponent(
			`./Pages/${name}.jsx`,
			import.meta.glob("./Pages/**/*.jsx"),
		),
	setup({ el, App, props }) {
		const root = createRoot(el);

		root.render(
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<App {...props} />
					</LocalizationProvider>
				</PersistGate>
			</Provider>,
		);
	},
});

InertiaProgress.init({ color: "#4B5563" });
