import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const changelogApi = createApi({
	reducerPath: "changelogApi",
	tagTypes: ["changelogs"],
	baseQuery: axiosFetchBase("/changelog"),
	endpoints: (builder) => ({
		getChangelogs: builder.query({
			query: () => ({
				url: "/",
				method: "GET",
			}),
			providesTags: [{ type: "changelogs", id: "list" }],
		}),
		getChangelogById: builder.query({
			query: (arg) => ({
				url: `/${arg.id}`,
				method: "GET",
				initial: arg.initial,
			}),
			providesTags: (_, __, arg) => [{ type: "changelogs", id: arg.id }],
		}),
		createChangelog: builder.mutation({
			query: (data) => ({
				url: "/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: [{ type: "changelogs", id: "list" }],
		}),
		updateChangelog: builder.mutation({
			query: (data) => ({
				url: `/${data.id}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_, __, arg) => [
				{ type: "changelogs", id: arg.id },
				{ type: "changelogs", id: "list" },
			],
		}),
		deleteChangelog: builder.mutation({
			query: (data) => ({
				url: `/delete/${data.id}`,
				method: "DELETE",
				body: data,
			}),
			invalidatesTags: (_, __, arg) => [{ type: "changelogs", id: arg.id }],
		}),
	}),
});

export const {
	useGetChangelogsQuery,
	useGetChangelogByIdQuery,
	useCreateChangelogMutation,
	useUpdateChangelogMutation,
	useDeleteChangelogMutation,
} = changelogApi;
