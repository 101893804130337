import { TYPES_AUTH } from "./auth.types";

const INITIAL_STATE = {
	login: {
		payload: null,
		error: null,
		loading: false,
	},
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TYPES_AUTH.AUTH_LOGIN_LOADING:
			return {
				...state,
				login: {
					loading: action.isLoading,
				},
			};
		case TYPES_AUTH.AUTH_LOGIN:
			return {
				...state,
				login: {
					payload: action.payload,
					error: action.error,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default authReducer;
