import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
} from "redux-persist/es/constants";
import blogReducer from "@/redux/blog/blog.reducer.js";
import productReducer from "@/redux/product/product.reducer.js";
import memberReducer from "@/redux/member/member.reducer.js";
import bantuanPesertaReducer from "@/redux/bantuan-peserta/bantuan-peserta.reducer.js";
import authReducer from "@/redux/auth/auth.reducer.js";
import transactionReducer from "@/redux/transaction/transaction.reducer.js";
import participantReducer from "@/redux/participant/participant.reducer.js";
import quizReducer from "@/redux/quiz/quiz.reducer.js";
import { changelogApi } from "@/services/changelog.service.js";

const store = configureStore({
	reducer: persistReducer(
		{
			key: "root",
			storage,
			whitelist: "auth",
		},
		combineReducers({
			blog: blogReducer,
			product: productReducer,
			member: memberReducer,
			assist: bantuanPesertaReducer,
			auth: authReducer,
			transaction: transactionReducer,
			participant: participantReducer,
			quiz: quizReducer,
			[changelogApi.reducerPath]: changelogApi.reducer,
		}),
	),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(thunk, changelogApi.middleware),
	devTools: true,
});
const persistor = persistStore(store);

export { store, persistor };
