import { CONSTANTS } from "@/utils";
import axios from "axios";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const http = axios.create({
	baseURL: API_BASE_URL,
	timeout: 10000, // bail after 10 seconds
});

const getAuthConfig = () => {
	const token = localStorage.getItem(CONSTANTS.AUTH_TOKEN);
	return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
};

/**
 * Factory function to create a fetch function for rtk query using axios
 * @param {string} baseUrl In the form of `/name`. Ex: `/user`, `/changelog`, etc.
 * @returns {(function(*, *): Promise<any|{error: string}|{error: string}|undefined>)|*}
 */
export function axiosFetchBase(baseUrl) {
	return async (args, api) => {
		if (args.initial) return { data: args.initial };
		try {
			const formData = new FormData();
			for (const key in args.body) {
				formData.append(key, args.body[key]);
			}
            // laravel / php workaround since they only support GET and POST method
            if (args.method !== "GET") {
                formData.append("_method", args.method);
            }
			const response = await http(`${baseUrl}${args.url}`, {
				signal: api.signal,
				data: formData,
				method: args.method === "GET" ? "GET" : "POST",
				...getAuthConfig(),
			});
			if (response.status >= 200 && response.status < 300) {
				return { data: response.data.payload };
			}
			return { error: response.statusText };
		} catch (e) {
			return { error: "Internal Server Error" };
		}
	};
}

export default http;

export { getAuthConfig, API_BASE_URL };
