import moment from "moment";
import phpUnserialize from "phpunserialize";
import { notify } from "react-notify-toast";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

moment.updateLocale("id", {
	months: [
		"Januari",
		"Februari",
		"Maret",
		"April",
		"Mei",
		"Juni",
		"Juli",
		"Agustus",
		"September",
		"Oktober",
		"November",
		"Desember",
	],
	monthsShort: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"Mei",
		"Jun",
		"Jul",
		"Ags",
		"Sep",
		"Okt",
		"Nov",
		"Des",
	],
	weekdays: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
	weekdaysShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
	weekdaysMin: ["Mg", "Sn", "Sl", "Rb", "Km", "Jm", "Sb"],
	longDateFormat: {
		LT: "HH.mm",
		LTS: "HH.mm.ss",
		L: "DD/MM/YYYY",
		LL: "D MMMM YYYY",
		LLL: "D MMMM YYYY [pukul] HH.mm",
		LLLL: "dddd, D MMMM YYYY [pukul] HH.mm",
	},
	meridiemParse: /pagi|siang|sore|malam/,
	meridiemHour: (hour, meridiem) => {
		if (hour === 12) {
			hour = 0;
		}
		if (meridiem === "pagi") {
			return hour;
		} else if (meridiem === "siang") {
			return hour >= 11 ? hour : hour + 12;
		} else if (meridiem === "sore" || meridiem === "malam") {
			return hour + 12;
		}
	},
	meridiem: (hours, minutes, isLower) => {
		if (hours < 11) {
			return "pagi";
		} else if (hours < 15) {
			return "siang";
		} else if (hours < 19) {
			return "sore";
		} else {
			return "malam";
		}
	},
	calendar: {
		sameDay: "[Hari ini pukul] LT",
		nextDay: "[Besok pukul] LT",
		nextWeek: "dddd [pukul] LT",
		lastDay: "[Kemarin pukul] LT",
		lastWeek: "dddd [lalu pukul] LT",
		sameElse: "L",
	},
	invalidDate: "Tanggal tidak valid",
});

const disablePageScroll = (isDisable = true) => {
	const elHTML = document.querySelector("html");

	if (isDisable) elHTML.style.overflowY = "hidden";
	else elHTML.style.removeProperty("overflow-y");
};

const discountAmount = (discount, maxAmount, total) => {
	const amount = total * (discount / 100);
	const fixedAmount = amount > maxAmount ? maxAmount : amount;
	return fixedAmount;
};

const copyText = (text, successMessage = "Berhasil disalin") => {
	const textArea = document.createElement("textarea");
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.select();
	document.execCommand("Copy");
	textArea.remove();
	notify.show(successMessage, "success", 3000);
};

const truncateText = (text, maxLength = 10) => {
	let result = text.substr(0, maxLength);
	if (text.length > maxLength) result += "...";
	return result;
};

const handleBrokenImage = (e) => {
	e.target.src = require("@/images/default-image.png");
};

const queryStringToObject = (queryString) => {
	const pairs = queryString.substring(1).split("&");

	const array = pairs.map((el) => {
		const parts = el.split("=");
		return parts;
	});

	return Object.fromEntries(array);
};

const numberFormat = (input) => {
	const number = input.toString();
	const split = number.split("").reverse();
	const addDot = split.map((digit, index) =>
		index % 3 === 0 && index !== 0 ? `${digit}.` : digit,
	);
	const output = addDot.reverse().join("");

	return output;
};

const objectToQueryString = (obj) => {
	const params = new URLSearchParams();
	for (const key in obj) {
		params.append(key, obj[key]);
	}
	return "?" + params.toString();
};

const showProgressService = (
	title = "Sedang diproses",
	text = "Mohon tunggu",
) => {
	return Swal.fire({
		title: "Sedang diproses",
		text: "Mohon tunggu",
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: false,
	});
};

const showNotificationServiceError = (error) => {
	let notifyMessage = "<p>Terjadi Kesalahan</p>";
	let notifyType = "error";
	let notifyTitle = "Oops...";

	if (error.status === 500 || error.status === 404) {
		notifyType = "error";
		notifyMessage = `${error.status} : ${error.statusText}`;
	} else if (error.data) {
		if (error.data.errors) {
			notifyTitle = "Perhatian";
			notifyType = "warning";
			notifyMessage =
				"<p><strong>Mohon perhatikan isian form kamu ya</strong></p></br>";
			let errorCount = 0;
			for (const key in error.data.errors) {
				const errors = error.data.errors[key];
				if (Array.isArray(errors)) {
					for (const errorMsg of errors) {
						errorCount++;
						notifyMessage += `${errorCount}. ${errorMsg}<br>`;
					}
				} else {
					errorCount++;
					notifyMessage += `${errorCount}. ${errors}<br>`;
				}
			}
		} else if (error.data.message) {
			notifyType = "warning";
			notifyMessage = error.data.message;
			notifyTitle = "Perhatian";
		}
	}

	return Swal.fire({
		title: notifyTitle,
		icon: notifyType,
		html: notifyMessage,
	});
};

const showNotificationServiceErrorImport = (error) => {
	let notifyMessage = "<p>Terjadi Kesalahan</p>";
	let notifyType = "error";
	let notifyTitle = "Oops...";

	if (error.status === 500 || error.status === 404) {
		notifyType = "error";
		notifyMessage = `${error.status} : ${error.statusText}`;
	} else if (error.data) {
		if (error.data.errors) {
			notifyTitle = "Perhatian";
			notifyType = "warning";
			notifyMessage =
				"<p><strong>Mohon perhatikan isian form kamu ya</strong></p></br>";
			let errorCount = 0;
			Object.keys(error.data.errors).forEach((key) => {
				var line = Number(key.split(".")[0]) + 2;
				var errors = error.data.errors[key];
				// Jika error ketika validasi payload
				if (Array.isArray(errors)) {
					errors.forEach((errorMsg) => {
						errorCount++;
						notifyMessage +=
							errorCount + ". " + errorMsg + ` (pada baris ${line})` + "<br>";
					});
				} else {
					errorCount++;
					notifyMessage += errorCount + ". " + errors + "<br>";
				}
			});
			// Jika error ketika 500 atau dari server
		} else if (error.data.message) {
			notifyType = "warning";
			notifyMessage = error.data.message;
			notifyTitle = "Perhatian";
		} else notifyType = "error";
	}

	return Swal.fire({
		title: notifyTitle,
		icon: notifyType,
		html: notifyMessage,
	});
};

const removeDuplicateObjectFromArray = (array) => {
	const objects = array.map(JSON.stringify);
	const uniqueSet = new Set(objects);
	const result = Array.from(uniqueSet).map(JSON.parse);

	return result;
};

const diffForHumans = (time) => {
	const date = moment(time);
	const diffForHumans = date.fromNow(false);
	return diffForHumans;
};

const notifConfirmation = ({ title, detail, successMessage, callback }) => {
	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton:
				"btn btn-success bg-green-ipbt px-2 py-1 rounded-md mr-4 text-white",
			cancelButton:
				"btn btn-danger bg-red-ipbt px-2 py-1 rounded-md text-white",
		},
		buttonsStyling: false,
	});

	swalWithBootstrapButtons
		.fire({
			title: title,
			text: detail,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Ya, saya yakin!",
			cancelButtonText: "Batal!",
			reverseButtons: false,
		})
		.then((result) => {
			if (result.isConfirmed) {
				swalWithBootstrapButtons.fire("Sukses", successMessage, "success");
				callback();
			} else if (
				/* Read more about handling dismissals below */
				result.dismiss === Swal.DismissReason.cancel
			) {
				swalWithBootstrapButtons.fire(
					"Dibatalkan",
					"tidak melakukan tindakan apapun :)",
					"error",
				);
			}
		});
};

const successNotif = (
	title = "Berhasil",
	message = "Berhasil melakukan permintaan",
) => {
	Swal.fire(title, message, "success");
};

export const successNotifContent = (title, content) => {
	Swal.fire({
		title: title,
		html: content,
		icon: "success",
	});
};

const dateSearch = (string) => {
	const dateTime = string !== null && moment(string).format("YYYY-M-D");
	return string !== null ? dateTime : "";
};

/**
 * Pretty much just a wrapper for .find(), not sure why the previous codebase has this
 * @template TValue
 * @param {TValue[]} object
 * @param {any} target
 * @returns {TValue}
 */
const matchValue = (object, target) => {
	return object.find((val) => val.value === target);
};

const formatDateTime = (string) => {
	const dateTime = moment(string).format("yyyy-MM-DD HH:mm:ss");
	return dateTime;
};

const formatDate = (string) => {
	const date = moment(string).format("yyyy-MM-DD");
	return date;
};

const formatDateHuman = (string) => {
	const date = moment(string).format("DD/MM/yyyy");
	return date;
};

const formatTime = (string) => {
	const time = moment(string).format("HH:mm:ss");
	return time;
};

const formatYear = (string) => {
	const year = moment(string).format("yyyy");
	return year;
};

const feedbackDataTableNotfound = () => {
	// Generate a random number between 1 and 10
	const randomNumber = Math.floor(Math.random() * 3) + 1;

	// Check if the random number is greater than 1
	if (randomNumber === 1) {
		return "Eits, nggak ada data di sini! Kayaknya dia lagi kabur ke tempat lain deh.";
	} else if (randomNumber === 2) {
		return "Waduh, kok nggak ada data ya? Jangan-jangan data-nya lagi sibuk jadi influencer di tabel lain.";
	} else if (randomNumber === 3) {
		return "Maaf ya, aku nggak nemu data yang kamu cari. Mungkin lagi galau dan ingin mencari jati diri di tabel lain.";
	}
};

const isOfflineTraining = (studyMethod) => {
	const isOfflineTraining =
		studyMethod === null || studyMethod === 1 || studyMethod === 3;
	return isOfflineTraining;
};

export const formatDateTraining = (dateFrom, dateEnd) => {
	let dateString;
	if (moment(dateFrom).format("Y-M-D") == moment(dateEnd).format("Y-M-D")) {
		dateString = dateInd(dateFrom);
	} else if (moment(dateFrom).format("Y-m") == moment(dateFrom).format("Y-m")) {
		dateString =
			moment(dateFrom).locale("id").format("Do") +
			"-" +
			moment(dateEnd).locale("id").format("Do MMMM YYYY");
	} else if (moment(dateFrom).format("Y") == moment(dateEnd).format("Y")) {
		dateString =
			moment(dateFrom).locale("id").format("Do MMMM") +
			" - " +
			dateInd(dateEnd);
	} else {
		dateString = dateInd(dateFrom) + " - " + dateInd(dateEnd);
	}
	return dateString;
};

export const formatDateTrainingFull = (dateFrom, dateEnd) => {
	let dateString;
	if (moment(dateFrom).format("Y-m-d") == moment(dateEnd).format("Y-m-d")) {
		dateString = dateInd(dateFrom);
	} else if (moment(dateFrom).format("Y-m") == moment(dateFrom).format("Y-m")) {
		dateString =
			moment(dateFrom).locale("id").format("Do MMMM") +
			"-" +
			moment(dateEnd).locale("id").format("Do MMMM YYYY");
	} else if (moment(dateFrom).format("Y") == moment(dateEnd).format("Y")) {
		dateString =
			moment(dateFrom).locale("id").format("Do MMMM") +
			" - " +
			dateInd(dateEnd);
	} else {
		dateString = dateInd(dateFrom) + " - " + dateInd(dateEnd);
	}
	return dateString;
};

export const trainerRatings = (trainerRatings) => {
	let total = 0;
	let count = 0;

	for (let i = 0; i < trainerRatings.length; i++) {
		const rating = trainerRatings[i];
		if (rating.penyampaian_rating >= 1 && rating.penyampaian_rating <= 4) {
			total += rating.penyampaian_rating;
			count++;
		}
	}

	if (count > 0) {
		const average = total / count;
		return average.toFixed(2);
	} else {
		return 0;
	}
};

export const dateInd = (value) => {
	return moment(value).locale("id").format("Do MMMM YYYY");
};

export const dateTimeInd = (value) => {
	return moment(value).locale("id").format("Do MMMM YYYY HH:mm");
};

export const IDR = (angka) => {
	var rupiah = "";
	var angkarev = angka.toString().split("").reverse().join("");
	for (var i = 0; i < angkarev.length; i++)
		if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
	return (
		"Rp " +
		rupiah
			.split("", rupiah.length - 1)
			.reverse()
			.join("")
	);
};

export const moneyFormat = (angka, separator = ".") => {
	if (!angka) {
		return 0;
	}
	var rupiah = "";
	var angkarev = angka.toString().split("").reverse().join("");
	for (var i = 0; i < angkarev.length; i++)
		if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + separator;
	return rupiah
		.split("", rupiah.length - 1)
		.reverse()
		.join("");
};

export const formatNumberKPI = (angka) => {
	if (angka.toString().includes(".")) {
		return angka;
	}
	var rupiah = "";
	var angkarev = angka.toString().split("").reverse().join("");
	for (var i = 0; i < angkarev.length; i++)
		if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
	return rupiah
		.split("", rupiah.length - 1)
		.reverse()
		.join("");
};

export const trainingCertificate = (
	dateFrom,
	dateEnd,
	productId,
	participantId,
) => {
	const dateBegin = moment(dateFrom);
	const dateEnded = moment(dateEnd);
	const certificateCode =
		dateBegin.year() +
		dateBegin.format("MM") +
		dateBegin.format("DD") +
		dateEnded.format("DD") +
		"/#" +
		productId +
		"/" +
		participantId;
	return certificateCode;
};

export const unserializePeserta = (serializedIdPeserta) => {
	const data = phpUnserialize(serializedIdPeserta);
	return data;
};

export const toTrueOrFalse = (value) => {
	switch (value) {
		case 0:
			return { value: 0, label: "Salah" };
		case 1:
			return { value: 1, label: "Benar" };
		default:
			break
	}
}

export const toQuizTimeLimit = (time) => {
	switch (time) {
		case 15:
			return { value: 15, label: "15 detik" };
		case 30:
			return { value: 30, label: "30 detik" };
		case 45:
			return { value: 45, label: "45 detik" };
		case 60:
			return { value: 60, label: "1 menit 0 detik" };
		case 75:
			return { value: 75, label: "1 menit 15 detik" };
		case 90:
			return { value: 90, label: "1 menit 30 detik" };
		case 105:
			return { value: 105, label: "1 menit 45 detik" };
		case 120:
			return { value: 120, label: "2 menit 0 detik" };
		case 135:
			return { value: 135, label: "2 menit 15 detik" };
		case 150:
			return { value: 150, label: "2 menit 30 detik" };
		default:
			break
	}
}

export const isPastOverdue = (targetDate, valueDate) => {
	const date1 = moment(targetDate);
	const date2 = moment(valueDate);
	return date2.isAfter(date1);
};

export const HELPERS = {
	disablePageScroll,
	copyText,
	numberFormat,
	discountAmount,
	handleBrokenImage,
	truncateText,
	queryStringToObject,
	objectToQueryString,
	showNotificationServiceError,
	removeDuplicateObjectFromArray,
	diffForHumans,
	notifConfirmation,
	successNotif,
	feedbackDataTableNotfound,
	dateSearch,
	isOfflineTraining,
	formatDateTime,
	formatDate,
	formatTime,
	matchValue,
	successNotifContent,
	formatDateTraining,
	formatDateTrainingFull,
	trainerRatings,
	toQuizTimeLimit,
	toTrueOrFalse,
	IDR,
	trainingCertificate,
	showProgressService,
	unserializePeserta,
	moneyFormat,
	formatDateHuman,
	formatNumberKPI,
	dateInd,
	showNotificationServiceErrorImport,
	dateTimeInd,
	formatYear,
	isPassOverDue: isPastOverdue,
};
